import dayjs, { Dayjs } from "dayjs";
import { PublicImages } from "./publicImages";
import { Public } from "@mui/icons-material";

export type ArticleType = {
  id: number;
  title: string;
  images: string[];
  text: string;
  date: Dayjs;
  url: string;
  position?: string;
};

export const Articles: ArticleType[] = [
  {
    id: 1,
    title: "Handbike-kolo pro Kristianka",
    images: PublicImages.articles.handbike,
    text: "Zásluhou skvělé party lidí z Kuraray Europe Moravia podepsal Krystián ve čtvrtek smlouvu o převzetí kola.\nDíky lidem se srdcem na správném místě, pomáháme dětem nepřetržitě po celou dobu karanténních opatření.\nKolo bylo předáno ve čtvrtek 24. 5. 2021 zaměstnanci Kuraray za přítomnosti Tomáše Poucha z nadace Černých koní, který kolo vyrobil.",
    date: dayjs("2021-05-24"),
    url: "handbike-kolo",
  },
  {
    id: 2,
    title: "Nadace DEKA z Lásky",
    images: PublicImages.articles.dekazlasky,
    text: 'Zapsaný spolek Deky z lásky je charitativní sdružení, které organizuje a zajišťuje šití patchworkových dek pro děti v obtížné životní situaci - pro vážně nemocné, dlouhodobě nemocné nebo jinak vážně postižené děti z ČR ve věku do 16 let s obrázky podle jejich přání.\nMyšlenka šít patchworkové deky pro vážně nemocné nebo handicapované děti vznikla ve Spojených státech již roku 1999. Od té doby se rozšířila do celého světa a byly ušity stovky a možná i tisíce takových dek v různých projektech, např. v USA s názvem "Love Quilts", v Polsku "Kolderek za jeden usmiech", ve Velké Británii "Solak", v Německu "Kinderlaecheln", Austrálii "Quilts 4 Kids". A Česká republika se připojila na jaře roku 2012 právě prostřednictvím z. s. Deky z lásky.\nA komu vlastně deky pomáhají ? Deky z lásky mají především udělat radost nemocným dětem. Zahřívají je a jsou jejich společníkem i v době, kdy jsou dlouhodobě upoutány na lůžko, při pobytech v nemocnici či na rehabilitačních pobytech. Mají svého majitele provázet při hrách, na cestách. Mají mu dávat pocit bezpečí, připomínat mu domov v těžkých chvílích, poskytovat útěchu. Deky z lásky mají ale i povzbudit celou rodinu v těžké situaci. Pro rodiče dětí jsou útěchou a poselstvím, že na boj se zlým osudem nejsou sami. Že někde tam venku jsou lidé, kteří na ně myslí, kterým nejsou lhostejní. Lidé, kteří jsou ochotní pro druhé něco udělat jen tak, pro dobrý pocit, jenom proto, aby vykouzlili úsměv na dětské tvářičce. Deky z lásky ale pomáhají i švadlenkám-dobrovolnicím, které na nich pracují. Mnohé z nich díky dekám z lásky mají pocit, že nejsou zbytečné, že ještě mohou dělat užitečné věci, dokážou potěšit, udělat radost.\nKristiánek dostal deky v letech 2014 a následně druhou (větší, jak vyrostl) v roce 2019, kdy mu bylo 6 roků.',
    date: dayjs("2019-12-31"),
    url: "nadace-deka-z-lasky",
  },
  {
    id: 3,
    title: "Brýle od Wagnerovy optiky",
    images: PublicImages.articles.optika,
    text: "Wagnerova optika Prostějov darovala Kristiankovi již druhé brýle. Ty první darovala 19. 4. 2014.\nTato optika je prostě nejlepší u nás v Prostějově. A co teprve majitel pan Wagner,další človíček se srdcem na správném místě. Osobně, s úsměvem a láskou již po druhé daroval Kristiankovi brýlečky, protože které mu daroval před 2,5 mu už byly malé a změnily se i dioptrie.\n Brýle od nás se stanou nejen Vaši korekční pomůckou, ale i módním doplňkem té nejvyšší kvality.\nLidskost a profesionalita je u nás na prvním místě. Neexistuje možnost, že byste od nás odcházeli nespokojeni. Ctíme pravidlo, že náš zákazník je náš pán. Od vyšetření zraku vysokoškolsky vzdělaným odborníkem až po zhotovení korekční pomůcky s perfektním servisem.\nJiž deset let se staráme o Váš zrak. Naším posláním je profesionálně odvádět svou práci.",
    date: dayjs("2019-09-27"),
    url: "bryle-od-wagnerovy-optiky",
    position: "0 -40px",
  },
  {
    id: 4,
    title: "Nadace AGEL",
    images: PublicImages.articles.agel,
    text: "Nadace AGEL přispěla v roce 2018 Kristiánkovi na zakoupení vozíčku.\nV roce 2019 přispěla doplatek na zádržný systém - autosedačku. Děkujeme za pomoc. O Kristiánkovi v souvislosti s tím napsal článek také Prostějovský deník: https://prostejovsky.denik.cz/zpravy_region/kristian-bojuje-s-nemoci-ale-nevzdava-se-rad-si-hraje-vyletuje-a-miluje-psy-2020.html",
    date: dayjs("2019-01-8"),
    url: "nadace-agel",
  },
  {
    id: 5,
    title: "Prostějovský deník",
    images: PublicImages.articles.denik,
    text: 'Prostějovský deník daroval Kristiankovi vozík za kolo.\nŠťastný úsměv. Sobotní slunečné odpoledne rozzářilo tváře většině teplomilných lidí. Jeden malý človíček však měl v očích slzy štěstí. Dostal totiž vozík pro přepravu dětí na kole.\n"Jsem móóc rád," culil se zanedlouho pětiletý Kristián Koucký. Jeho maminka navíc dostala trekingové jízdní kolo.\n"Je to úžasné, jak dokážou lidi pomáhat. Já jsem s klukama sama a rozhodně bych si vozíček ani kolo neměla z čeho koupit," děkovala Alena Koucká a dodala: "Já moc na kole nejezdím. Jezdím na bruslích, ale to moc s vozíčkem nejde. Takže kolo využiju a hrozně se těším."\nVšechno měl na svědomí Kiwanis klub, ke kterému se přidal hejtman Olomouckého kraje Ladislav Okleštěk.\n"Já mám v Kiwanis klubu kamarády. Řekli mi, že mají pro handicapované děti připravený vozíček. Já se tedy rozhodl, že věnuji druhý vozíček osobně. Předáváme to na dopravním hřišti, protože chceme aby děti znaly předpisy a předcházely nehodám," komentoval hejtman.\nProstějovské dopravní hřiště bylo v sobotním odpoledni plné úsměvů. Zástupci Kiwanis klubu společně s hejtmanem nezapomněli ani na další děti a na každého se něco dostalo.\nZdroj: https://prostejovsky.denik.cz/zpravy_region/maly-kristianek-dostal-od-hejtmana-vozik-za-kolo-20180414.html',
    date: dayjs("2021-07-18"),
    url: "prostejovsky-denik",
  },
  {
    id: 6,
    title: "Rehabilitační pobyt Chorvatsko - Makarská 2019",
    images: PublicImages.articles.makarska2019,
    text: "Ozdravně rehabilitační pobyt v Chorvatsku - Makarská - hotel Biokovka - bezbarierové hotelové zdravotní středisko",
    date: dayjs("2019-09-16"),
    url: "makarska-2019",
  },
  {
    id: 7,
    title: "Rehabilitační pobyt Chorvatsko - Makarská 2018",
    images: PublicImages.articles.makarska2018,
    text: "Ozdravně rehabilitační pobyt v Chorvatsku - Makarská - hotel Biokovka - bezbarierové hotelové zdravotní středisko",
    date: dayjs("2018-09-17"),
    url: "makarska-2018",
  },
  {
    id: 8,
    title: "Nadace Sluníčko pro děti",
    images: PublicImages.articles.slunicko,
    text: "Poděkování patří nadaci Sluníčko  pro děti, která v r. 2014 a 2016 Kristiankovi přispěla na tyto pomůcky: Velký válecí vak, nosící šátek, doplatek na polohovací židličku, lehátko do vany, a částka na remodelační helmičku, kterou nutně potřebuje.\nPro zlepšení efektivity každodenního cvičení a rehabilitací mu již slouží kompenzační pomůcky v hodnotě 16.900 Kč - je to část výtěžku z golfového turnaje v r. 2014.",
    date: dayjs("2016-12-21"),
    url: "nadace-slunicko-pro-deti",
  },
  {
    id: 9,
    title: "Rehabilitační pobyt Chorvatsko - Makarská 2017",
    images: PublicImages.articles.makarska2017,
    text: "Ozdravně rehabilitační pobyt v Chorvatsku - Makarská - hotel Biokovka - bezbarierové hotelové zdravotní středisko",
    date: dayjs("2017-09-11"),
    url: "makarska-2017",
  },
  {
    id: 10,
    title: "Rehabilitační pobyt Chorvatsko - Kaštel Stafilič 2016",
    images: PublicImages.articles.kastel_stafilic,
    text: "Ozdravně rehabilitační pobyt u moře v Chorvatsku v Kaštel Stafilič.\nLetošní pobyt u moře Kikimu moc prospěl. Zahrabávání do teplých kamínky, stalý pobyt v moři, byla pro něj užasná rehabilitace, která ho posunula zase o kus dál.",
    date: dayjs("2016-09-12"),
    url: "kastel-stafilic-2016",
  },
  {
    id: 11,
    title: "Kinderkamionaci.eu pomáhají",
    images: PublicImages.articles.kinderkamionaci,
    text: "Velice děkujeme nejen pořadateli Františku Altmanovi za pozvání a dárky, ale i všem kamionákům a ostatním za skvělej den pro Kikiho.",
    date: dayjs("2021-07-24"),
    url: "kinderkamionaci-pomahaji",
  },
  {
    id: 12,
    title: "Rehabilitační pobyt Chorvatsko - Makarská 2021",
    images: PublicImages.articles.makarska2021,
    text: "Ozdravně rehabilitační pobyt v Chorvatsku - Makarská - hotel Biokovka - bezbarierové hotelové zdravotní středisko",
    date: dayjs("2021-08-30"),
    url: "makarska-2021",
  },
  {
    id: 13,
    title: "Rehabilitační pobyt Chorvatsko - Makarská 2022",
    images: PublicImages.articles.makarska2022,
    text: "Ozdravně rehabilitační pobyt v Chorvatsku - Makarská - hotel Biokovka - bezbarierové hotelové zdravotní středisko",
    date: dayjs("2022-08-29"),
    url: "makarska-2022",
  },
  {
    id: 14,
    title: "Setkání se slavými osobnostmi",
    images: PublicImages.articles.herci,
    text: "Kristiánek se postupně setkal s několika slavnými osobnostmi.\nNejprve 10. 11. 2018 potkal herečku Valérii Zawadskou, potom 21. 1. 2022 zpěváka Bena Cristovao a 17. 9. 2022 také zpěvačku Olgu Lounovou.",
    date: dayjs("2022-09-17"),
    url: "setkani-se-slavnymi-osobnostmi",
  },
  {
    id: 15,
    title: "Pobyt s APA na horách",
    images: PublicImages.articles.apa,
    text: "Pobyt na horách na monoski s centem aplikovaných pohybových aktivit (APA) při fakultě Tělesné kultury na Univerzitě Palackého v Olomouci.\nHorský vzduch spojený s pohybem na monoski lyži je skvělá terapie.",
    date: dayjs("2023-02-10"),
    url: "apa",
  },
  {
    id: 16,
    title: "Dárky od Olomoucké drbny",
    images: PublicImages.articles.olomouckadrbna,
    text: "Dne 20. 11. 2018 obrdržel Kristiánek dárky od Olomoucké drbny.",
    date: dayjs("2018-11-20"),
    url: "darky-od-olomoucke-drbny",
    position: "top",
  },
  {
    id: 17,
    title: "Focení kalendáře s divadlem Tramtaria",
    images: PublicImages.articles.tramtaria,
    text: "Setkání a focení kalendáře s herci z Olomouckého divadla Tramtaria.",
    date: dayjs("2018-10-16"),
    url: "foceni-kalendare-tramtaria",
  },
  {
    id: 18,
    title: "Oslava 20. let OC Haná",
    images: PublicImages.articles.zabavny_den_oc_hana,
    text: "Kristianek se zúčastnil zábavního dne při oslavách 20. let OC Haná Olomouc.\nSetkal se se železným mužem, který mu podepsal fotku s osobním věnováním. A také se zpěvačkami Adélou a Veronikou Dobešovou, které mu věnovaly CD s podpisem.\nOC Haná darovala přes portál Donio Kristiánkovi 20.000 Kč, které byly použity na rehabilitační pobyt u moře 2023. Děkujeme.",
    date: dayjs("2022-09-17"),
    url: "oc-hana-zabavny-den",
  },
  {
    id: 19,
    title: "Canisterapie",
    images: PublicImages.articles.canisterapie,
    text: "Canisterapii můžeme definovat jako léčebné působení psa na člověka. Je jednou z forem ucelené rehabilitace a je založena na vzájemné pozitivní interakci člověka a psa, které se využívá ke zlepšení či udržení zdravotního, sociálních dovedností, znalostí a kvality života jedince.",
    date: dayjs("2013-06-13"),
    url: "canisterapie",
  },
  {
    id: 20,
    title: "Muzikoterapie",
    images: PublicImages.articles.muzikoterapie,
    text: "Muzikoterapie je terepautický obor, který využívá hudební prvky (zvuk, rytmus, harmonie a melodie) k hudebním i nehudebním cílům. Muzikoterapie může být cílená jako léčba, osobní rozvíjení člověka.",
    date: dayjs("2013-06-13"),
    url: "muzikoterapie",
  },
  {
    id: 21,
    title: "Stylová párty",
    images: PublicImages.articles.stylova_party,
    text: "Stylová charitativní párty s kapitánem DEMO pro výběr peněz na elektrický vozík pro Kristianka.",
    date: dayjs("2022-10-07"),
    url: "stylova-party",
    position: "top",
  },
  {
    id: 22,
    title: "Společnost pro ranou péči",
    images: PublicImages.articles.ranapece,
    text: 'Rádi bychom představili Společnost pro ranou péči.\nCo je raná péče?\nMáte obavu, že s Vaším dítětem není něco v pořádku? Je Vaše dítě předčasně narozené? Má Vaše dítě opožděný psychomotorický vývoj? Má Vaše dítě zdravotní postižení? Potřebujete odborně poradit ohledně vývoje Vašeho dítěte? ... Nejen s těmito otázkami Vám pomůže Společnost pro ranou péči.\nRaná péče je terénní služba, popřípadě doplněná ambulantní formou služby, poskytovaná dítěti a rodičům dítěte, které je zdravotně postižené, nebo jehož vývoj je ohrožen v důsledku nepříznivého zdravotního stavu.\n"Záleží nám na tom, aby se o rané péči dozvěděl každý rodič dítěte se zdravotními problémy, co nejdříve. Nejrychlější cestou je, pokud už v porodnici dostanou rodiče kontakt na ranou péči v případě, že se jejich miminko narodilo předčasně, když se u něj zjistí zdravotní problémy nebo i pokud je třeba jen podezření na opožděný vývoj. Můžeme tak pomoci nejen s rozvojem dítěte, ale také poskytnout rodičům podporu v těžkém období. Čím dříve se k rodině raná péče dostane, tím efektivněji umí pomáhat." (citace z facebookové stránky Společnosti pro ranou péči).',
    date: dayjs("2023-07-28"),
    url: "ranapece",
  },
  {
    id: 23,
    title: "Proč je moře důležité",
    images: PublicImages.articles.o_mori,
    text: "Slaná mořská voda a vzduch, to je dokonalá léčebná kombinace! Nám Čechům a Evropanům nejvíc vyhovuje a zároveň prospívá Jaderské moře, stejně přínosný ozdravný pobyt si však můžeme dopřát i v Řecku. Výzkum z roku 1984 totiž dokazuje, že tato moře jsou nejslanější a z léčebného hlediska proto i nejúčinnější.\nMořská voda obsahuje velké množství minerálů a stopových prvků, jako je například sodík, draslík, jód, brom, vápník, horčík či železo. Léčivé účinky mořské soli znali již staří Egypťané, kteří ji používali k hojení otevřených ran. Mořská voda působí na otevřenou ránu antibakteriálně, čímž brání vzniku infekce. Minerály a stopové prvky obsažené v mořské vodě také důkladně vyživují pokožku, urychlují látkovou výměnu a zlepšují krevní oběh. Účinky mořské vody ocení hlavně lidé s chronickými kožními nemocemi, jako je lupénka, atopický ekzém, nebo akné.\nSlaný a mokrý vzduch také prokazatelně pročišťuje dýchací cesty a napomáhá tak pacientům s dýcháním. V kombinaci se zdravější, odlehčenou stravou, kterou si obvykle na dovolené rádi dopřejeme, odpočinkem, dny strávenými v úplné absenci stresu a aktivním odpočinkem, např. v rámci vodních sportů či plážových her, je pobyt u moře pozitivní po všech stránkách. Pokud jde o skutečně léčivé účinky mořského klimatu, nečekejte však zázraky, jedete-li na dovolenou k moři třeba jen na týden. Mořské klima začíná ozdravně působit až zhruba po dvanácti dnech pobytu, po této době začne například uvolňovat dýchací cesty. Doporučuje se tak strávit u moře za účelem ozdravného působení zhruba tři týdny, což však bohužel není, zvláště pak pro rodiny s dětmi, levná záležitost.",
    date: dayjs(new Date()),
    url: "o-mori",
  },
  {
    id: 24,
    title: "Benefiční Mohelnická terénní časovka vol. 2",
    images: PublicImages.articles.roadventure,
    text: "RoadVentureRally pomáhá Kristiánkovi. 16. 9. 2023",
    date: dayjs("2023-08-28"),
    url: "roadventure-rally",
  },
  {
    id: 25,
    title: "Poděkování Ing. Danielovi Horákovi",
    images: PublicImages.articles.podekovani,
    text: "Jako každý rok při rehabilitačním pobytu v Chorvatsku jedeme na výlet do národního parku Biokovo a na SKYWALK. Pak se jde 25 minut pěšky k vysílači prudkým kopcem nahoru a 25 minut prudce nazpátek. Letos ze zdravotních důvodu mého kolene bych jít nemohla, a protože bych byla jediná, která by dole na všechny s Kristiankem musela počkat, jeden úžasný a hrozne hodný pan se nabídl, ze Kristianka nahoru a dolu vynese, aby nebyl smutný ze neuvidí moře z výšky. Tímto bych mu ještě jednou chtěla strašně moc poděkovat, bylo to od nej úžasné. A Kiki byl strašně moc rád. Tím pánem byl Ing. Daniel Horák, který pracuje jako makléř RE-Max Anděl.",
    date: dayjs("2023-08-21"),
    url: "podekovani",
    position: "top",
  },
  {
    id: 26,
    title: "Benefiční akce Mohelnické lomy",
    images: PublicImages.articles.mohelnickelomy,
    text: "Dne 16. 9. 2023 proběhla benefiční akce Mohelnické lomy. Tímto bychom chtěli velice poděkovat úžasnému chlapečkovi Kájínkovi a jeho úžasným rodičům, že do dražby darovali nádherný obraz, který Kájík sám namaloval. Obraz vydražil pan Vladimír Rozsypal a bude umístěný v restauraci Sport Mohelnice. Částka 2.500 Kč bude použita k rehabilitacím v zahraničí na rok 2024.\nZ celé této velké benefiční akce byla Kristiankovi darována částka 6.730 Kč.",
    date: dayjs("2023-09-16"),
    url: "mohelnicke-lomy",
  },
  {
    id: 27,
    title: "Poděkování OC Haná",
    images: PublicImages.articles.podekovani_oc_hana,
    text: "OC Haná v loňském roce přispělo na pomoc pro Kristiánka. Vzhledem k tomu, že Kristiánek vyrostl, dostal nový (větší) vozíček, bylo potřeba pořídit větší auto. Do něj se již bez problémů vejde a je vybaveno i nájezdovou rampou a zádržným systémem na bezpečné sezení, nastupování i vystupování. Stále ještě zbývá dofinancovat nákup tohoto, pro Kristiánka velmi důležitého, pomocníka. Přispět můžete i Vy. Děkujeme.",
    date: dayjs("2023-12-17"),
    url: "podekovani-oc-hana",
  },
  {
    id: 28,
    title: "Poděkování pvnovinky.cz",
    images: PublicImages.articles.podekovani_oc_hana,
    text: "Server pvnovinky.cz sepsal a sdílel článek o Kristiánkově sbírce na Donio.cz. Tímto mu moc děkujeme.",
    date: dayjs("2023-12-17"),
    url: "podekovani-pvnovinky",
  },
  {
    id: 29,
    title: "Poděkování městu Prostějov a paní Sokolové",
    images: PublicImages.articles.podekovani_mestu_a_sokolove,
    text: "Děkujeme městu Prostějov a paní Sokolové za to, že Kristiánek měl už 4. rokem po sobě možnost bruslit na náměstí a měl pro sebe volnější kluziště. Zažil tak bruslení alespoň na kolečkovém křesle.",
    date: dayjs("2023-12-17"),
    url: "podekovani-mestu-a-pani-sokolove",
    position: "bottom",
  },
  {
    id: 30,
    title: "Poděkování Středisku pro ranou péči",
    images: PublicImages.articles.podekovani_stredisku_pro_ranou_peci,
    text: "Děkujeme Středisku pro ranou péči Olomouc, že i letos jsme měli možnost pečení perníčků a výrobu adventního věnce. Byl to skvělý den s úžasnou atmosférou a skvělými lidmi, kteří pomáhají celým rodinám, kde je hendikepované dítě. Jsme za tuto službu nesmírně rádi a vděčni.",
    date: dayjs("2023-12-17"),
    url: "podekovani-stredisku-pro-ranou-peci",
  },
  {
    id: 31,
    title: "Návštěva od Kuraray",
    images: PublicImages.articles.navsteva_od_kuraray,
    text: "Dnes nás navštívili zástupci firmy Kuraray a přivezli Kristiánkovi spoustu dárků a strávili s námi dopoledne. Byl to skvělý den a Kristiánek měl z dárečků radost. Děkujeme.",
    date: dayjs("2023-12-18"),
    url: "navsteva-od-kuraray",
  },
  {
    id: 32,
    title: "Poděkování nadaci Agrofert",
    images: PublicImages.articles.phm,
    text: "Ještě jednou moc děkujeme nadaci AGROFERT, že nám pomohla na půl roku zaplatit pohonné hmoty. A to částkou každý měsíc částkou 2.000 Kč od ledna do června 2023. Jsme nadaci opravdu moc vděčni za jejich pomoc.",
    date: dayjs("2023-01-06"),
    url: "pohonne-hmoty",
  },
  {
    id: 33,
    title: "Monoski",
    images: PublicImages.articles.monoski,
    text: "Letošní zimní sezóna plánovanému Monoski lyžování moc nepřála. Sněhu je čím dál méně. Ale i přes nepříznivé počasaí jedno Monoski lyžování vyšlo, a to ve Ski areálu Kareš v Loučné nad Desnou. Byl to zase super den strávený na horách, na čerstvém vzduchu s úžasnými instruktory s centra APA - Fakulta Tělesné kultury Univerzity Palackého Olomouc. Moc jim děkujeme za to, že máme každý rok možnost lyžování na Monoski lyži.",
    date: dayjs("2024-02-04"),
    url: "monoski",
    position: "50% 40%",
  },
  {
    id: 34,
    title: "Ledové Sochy",
    images: PublicImages.articles.ledovesochy,
    text: "Čerstvý horský vzduch je oproti městskému poznat hned na první nadechnutí. A ve spojení s horským sluníčkem je to naprosto dokonalá kombinace.\nJaké jsou přínosy pobytu na horách? Vědecké studie dokázaly, že pobyt v horských oblastech zvyšuje fyzickou i duševní pohodu. Nadmořská výška stimuluje tvorbu červených krvinek v našem těle. Výsledkem je, že může být transportováno více kyslíku naši krví do tkání, svalů a dokonce i do našeho mozku. Hory mají vliv na lepší náladu, kvalitní spánek a posílení iminuty.",
    date: dayjs("2024-01-21"),
    url: "ledove-sochy",
    position: "50% 70%",
  },
  {
    id: 35,
    title: "Rampach 418m n. m.",
    images: PublicImages.articles.rampach,
    text: "Rampach je vrch na okraji Bouzovské vrchoviny a měl by být vyhaslou sopkou.\nJaký má význam les na člověka? Stromy a les jsou významným zdrojem kyslíku ve vzduchu.\nProč je dobré chodit do lesa? Čas strávený v přírodě má velmi pozitivní vliv. Pobyt v lese je sám o sobě velmi prospěšný pro lidské zdraví a psychickou pohodu.\nDěkujeme našim přátelům Vitáskovým z Choliny za příjemné pozvání k nim domů a strávený úžasný den s nimi na výletě při výšlapu na Rampach.",
    date: dayjs("2024-04-06"),
    url: "rampach",
    position: "50% 30%",
  },
  {
    id: 36,
    title: "Muzikoterapie II",
    images: PublicImages.articles.muzikoterapie_ii,
    text: "Muzikoterapie je terepautický léčebný prostředek. Hudba ovlivňuje vegetativní funkce jako je srdeční rytmus, krevní tlak, dýchání, svalový tonus, motoriku, termoregulaci, komunikaci. Děkujeme Středisku rané péče Olomouc, že máme možnost navštěvovat individuální muzikoterapii, která je pro Kristiánka velkým pozitivním přínosem. Také velké poděkování patří úžasnému panu Vilimkovi.",
    date: dayjs("2024-04-16"),
    url: "druha-muzikoterapie",
  },
  {
    id: 37,
    title: "Autotrakční lehátko MEDIC",
    images: PublicImages.articles.lehatko,
    text: "Moc děkujeme, že měl Kristianek možnost vyzkoušet toto úžasné lehátko, které mu opravdu ulevilo od jeho velké bolesti zad. I noc pak byla doma klidnější. Tímto děkujeme rodině Vitáskové z Choliny. Máme Vás rádi a těším se na další setkání s Vámi a Vaším smíškem Kajíčkem.",
    date: dayjs("2024-04-20"),
    url: "lehatko",
  },
  {
    id: 38,
    title: "Štafeta na vozíčku",
    images: PublicImages.articles.stafeta,
    text: "Spolek Trend vozíčkářů Olomouc pořádá už po dvanácté Olomouckou štafetu na vozíčku. Už po dvanácté tak oživila Smetanovy sady. Je dobré vědět, co všechno pohyb na vozíčku obnáší a jakými překážkami se vozíčkáři potýkají. Dnes si to mohli všichni vyzkoušet.",
    date: dayjs("2024-06-14"),
    url: "stafeta-na-vozicku",
  },
  {
    id: 39,
    title: "Poděkování za sbírku na DONIO",
    images: PublicImages.articles.donio,
    text: "Tímto bychom chtěli velice z celého srdce poděkovat všem, kteří nám přispěli jakoukoliv částkou či sdílením na sociálních sítích. Díky Vám jsme mohli doplatit částku, která nám chyběla na auto a také chybějící část na rehabilitační pobyt u moře. Takže opravdu srdečné poděkování patří Vám všem.\nA nesmím zapomenout zvlášť poděkovat neznámému dárci, který nám jednorázově přispěl úžasnou částkou 70 000 Kč. Děkujeme.\nNesmírně si vážíme vaši obrovské pomoci.\nDěkujeme, že nám pomáháte.",
    date: dayjs("2024-01-28"),
    url: "podekovani-za-sbirku-na-doniu",
    position: "50% 60%",
  },
  {
    id: 40,
    title: "Nadace Agrofert - auto pro hendikepované",
    images: PublicImages.articles.agrofert,
    text: "Tímto velice děkujeme nadaci AGROFERT za jejich příspěvek 50 000 Kč za doplatek na automobil pro Kristianka.\nVelice si této pomoci vážíme a jsme rádi, že jsme se na Vás mohli s pomoci obrátit.\nDěkujeme!",
    date: dayjs("2024-02-23"),
    url: "podekovani-nadaci-agrofert",
  },
  {
    id: 41,
    title: "Chorvatsko 2024",
    images: PublicImages.articles.chorvatsko,
    text: "Za letošní pobyt z celého srdce děkujeme všem dárcům, sponzorům na DONIU, a jednomu velkorysému dárci, který nám na portál DONIO přispěl krásných 70 000 Kč.\nKristiankovi pobyt u moře velice prospívá a pomáhá. Po opakovaném pobytu u moře se Kristianek vůbec nebojí vody. V moři se cítí být volný a svobodný. Nepotřebuje vozík, ortézu, naučil se plavat a vlny a hloubka pro něj nejsou žádnou překážkou. V moři se mu vždy zlepší vyrážka v okolí pegu, zahojí se krásně PEG, stabilizuje se mu astma. Nemá zde ani dýchací potíže ani revmatickou horečku, kterou doma často mívá.\nA protože všechno souvisí se vším, po psychické stránce je u moře také zlepšení.\nVšem tímto posíláme od moře srdečný pozdrav a vděčnost za zlepšení zdravotního stavu.\nDěkujeme.",
    date: dayjs("2024-09-01"),
    url: "chorvatsko-2024",
  },
  {
    id: 42,
    title: "MYMOTO Charitativní akce",
    images: PublicImages.articles.mymoto,
    text: "V červnu proběhl už třetí ročník úžasné benefiční akce Mymoto Párty s překrásným celkovým výtěžkem přes 150.000 Kč, který se rozdělil mezi Kájíka, Leonku a Kristiánka.\nZe srdce děkujeme všem, kteří se na akci podíleli, velmi si toho vážíme.\nMymoto CZ\nDana Mikulková\nPetr Bob Jiříček\nTomáš Kröner\nobec Drahanovice\nSDH Drahanovice\nTJ Sokol Drahanovice\nZe srdce děkujeme všem účinkujícím: VITRIOiL, Stracené Ráj, Pryor Rock, ZZ TOP Litovel - Czech Tribute, Lukáš Petráček\nTaké děkujeme všem sponzorům.\nA samozřejmě ze srdce děkujeme všem, kteří přišli akci podpořit.",
    date: dayjs("2024-06-15"),
    url: "mymoto",
  },
];
