import styled from "styled-components";
import { COLORS } from "../../styles/colors";
import { FONTS } from "../../styles/fonts";
import { media } from "../../styles/media";

export const Main = styled.div`
  margin: 0;
  padding: 140px 30px 50px;
  background-color: ${COLORS.white};
`;

export const FbVidWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 30px;
  padding: 30px;
`;

export const Text = styled.div`
  font-family: ${FONTS.primary};
  color: ${COLORS.black};
  font-size: 35px;
  letter-spacing: 1.2px;
  margin-top: 35px;
  text-align: justify;
  line-height: 2.5rem;
`;

export const Image = styled.img`
  width: 400px;
  height: 400px;
`;

export const Donio = styled.div`
  margin: 50px 0 10px;
  width: 300px;
  max-width: 90%;
  min-height: 300px;
  background-color: ${COLORS.first};
  border-radius: 25px;
  text-align: center;
  color: ${COLORS.white};
  padding: 15px;
  font-family: ${FONTS.primary};
  font-size: 18px;
  letter-spacing: 1.2px;
  line-height: 2.5rem;
`;

export const Title = styled.p`
  font-size: 25px;
  bold: 800;
`;

export const Link = styled.a`
  color: white;
  text-decoration: none;
  font-size: 30px;

  &:hover {
    text-decoration: underline;
  }
`;

export const FbVid = styled.iframe`
  width: 560px;
  height: 316px;

  ${media.maxSm`
        width: 250px;
        height: 141px;
        margin-bottom: 30px;
    `}
`;
