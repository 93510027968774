import { PublicImages } from "../../publicImages";
import AboutSection from "./aboutSection";
import LetterSection from "./letterSection";
import Sponzors from "./sponzors";
import * as S from "./styled";

const MainPage = () => {
  return (
    <S.Main>
      <AboutSection />
      <S.FbVidWrapper>
        <S.Text>Děkujeme Vám</S.Text>
        <S.Image src={PublicImages.heart} />
        <S.Text>Číslo účtu: 2901714229/2010</S.Text>
      </S.FbVidWrapper>
      <LetterSection />
      <Sponzors />
    </S.Main>
  );
};

export default MainPage;
